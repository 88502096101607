<template>
    <div :class="['contact-form', { contactForm : type == 'contact'}]">        
        <ValidationObserver ref="form" v-slot="{ handleSubmit }" :class="['form-controller',  { premium : $route.name == 'Premium'}]" >
            <template v-if="!success">
                <form @submit.prevent="handleSubmit(onSubmit)" class="form-container" >
                    <div class="form-row">
                        <div class="form-col">
                            <ValidationProvider class="form-item" rules="required|max:50" v-slot="{ errors }">
                                <input type="text" :class="['input-item', { 'input-error': errors[0] }]" v-model="firstname" placeholder="Prénom" />
                                <span class="notice-error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="form-col">
                            <ValidationProvider class="form-item" rules="required|max:50" v-slot="{ errors }">
                                <input type="text" :class="['input-item', { 'input-error': errors[0] }]" v-model="lastname" placeholder="Nom" />
                                <span class="notice-error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="form-row">
                        <ValidationProvider class="form-item" rules="required|max:50" v-slot="{ errors }">
                            <input type="text" :class="['input-item', { 'input-error': errors[0] }]" v-model="phone" placeholder="Téléphone" />
                            <span class="notice-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                    <div class="form-row">
                        <ValidationProvider class="form-item" rules="required|max:100|email" v-slot="{ errors }">
                            <input type="text" :class="['input-item', { 'input-error': errors[0] }]" v-model="email" placeholder="E-mail" />
                            <span class="notice-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                    <div class="form-row">
                        <ValidationProvider class="form-item" rules="required" v-slot="{ errors }">
                            <textarea :class="['input-item', { 'input-error': errors[0] }]" v-model="message" placeholder="Message" rows="7"></textarea>
                            <span class="notice-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                    <div class="form-submit">
                        <input type="submit" value="envoyer" class="btn" v-if="!loading" />
                        <div class="loader-container" v-else>
                            <div class="loader"><div></div><div></div><div></div><div></div></div>
                        </div>
                    </div>
                </form>
            </template>

        </ValidationObserver>
        <div :class="['notice-container', { premium : $route.name == 'Premium'}]">
            <div class="notice notice-success" v-show="success">Votre demande a bien été envoyée !</div>
            <div class="notice notice-failed" v-show="failed" v-html="failed"></div>
        </div>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import axios from 'axios'
export default {
    name : 'ContactForm',
    props : ['type'],
    components : {
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            phone : null,
            email : null,
            message : null,
            firstname : null,
            lastname : null,
            loading : false,
            success: false,
            failed : null
        }
    },
    methods : {
        onSubmit() {
            this.loading = true
            this.success = false
            this.failed = null

            let params = {
                phone : this.phone,
                email : this.email,
                message : this.message,
                firstname : this.firstname,
                lastname : this.lastname
            }

            let config = {
                validateStatus: () => true,
            };
            let vm = this

            axios
                .post('https://cabinet-facilimmo.com/mailer/sendmail.php', params, config)
                .then(function (res) {
                     if(res.status == 200) {
                        if(res.data.state == 'failed') {
                            vm.loading = false
                            vm.failed = res.data.msg
                        }else {
                            vm.loading = false
                            vm.success = true
                            // vm.phone = null
                            // vm.email = null
                            // vm.firstname = null
                            // vm.lastname = null
                            // vm.message = null
                        }
                    }else {
                         vm.loading = false
                        vm.failed = 'Une erreur est survenue, veuillez recommencer'
                    }
                })
        }
    }
}
</script>

<style lang="scss" scoped>


.form-container {

    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top:40px;
}

.form-row {
    display: flex;
    width:100%;
}

.form-item {
    width:100%;
    margin:10px 0;
}

.input-item {
    border:2px solid #fff;
    width: calc(100% - 40px);
    padding:15px 10px;
    background:#fff;
    font-size:15px;
    color:$text-color;
    border-radius:10px;
    font-family: $font;
    margin-right:10px;
    margin-left: 10px;
}

.input-item::placeholder {
    font-size:15px;
    color:$text-color; 
    font-family: $font;
}

.input-item:focus {
    outline: none;
    border:2px solid $secondary-color;
}

.input-item.input-error {
    border:2px solid $error;
}

.notice-error {
    margin-top:5px;
    margin-left: 20px;
    font-size:13px;
    color:$error;
    font-weight: 500;
}

.form-submit {
    margin-top: 20px;
}

.form-submit .btn {
    border:1px solid #fff;
}

.form-col {
    width:50%;
    display: flex;
}

.form-submit .btn:hover {
    border:1px solid $main-color;
}

.contact-form.contactForm .input-item {
    border:1px solid $main-color;
}

.form-controller {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width:700px;
    max-width: 90%;
    margin-left: 10%;
}

.form-controller.premium {
    margin:0 auto;
}

.notice {
    padding:10px 20px;
    background:#fff;
    border-radius:10px;
    margin:20px  auto;
}

.notice-success {
    color:$success;
    border:2px solid $success;
}

.notice-failed {
    color:$error;
    border:2px solid $error;
}

.notice-container {
    display: flex;
    justify-content: center;
    width: 700px;
    max-width: 90%;
    margin-left: 10%;
}

.notice-container.premium {
    margin:0 auto;
}

/* LOADER */

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.loader div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: loader-turn 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.loader div:nth-child(1) {
  animation-delay: -0.45s;
}
.loader div:nth-child(2) {
  animation-delay: -0.3s;
}
.loader div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes loader-turn {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width:768px) {
   .form-row {
       flex-direction: column;
   } 

   .form-col {
       width: 100%;
   }

   .form-controller, .notice-container {
       margin:0 auto;
   }
}

</style>